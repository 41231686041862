import { docReady } from "./utils";
import { handleNavbarVerticalCollapsed } from "./navbar-vertical";
import echartsInit from "./echarts";
import progressBar from "./progressbar";
import { detector } from "./detector";
import tooltipInit from "./tooltip";
import popoverInit from "./popover";
import navbarTopDropShadow from "./navbar-top";
import flatpickrInit from "./flatpickr";
import toastInit from "./toast";
import progressAnimationToggle from "./progress";
import glightboxInit from "./glightbox";
import plyrInit from "./plyr";

/* -------------------------------------------------------------------------- */
/*                            Theme Initialization                            */
/* -------------------------------------------------------------------------- */

docReady(detector);
docReady(handleNavbarVerticalCollapsed);
docReady(echartsInit.totalOrder);
docReady(echartsInit.weeklySales);
docReady(echartsInit.marketShare);
docReady(echartsInit.totalSales);
docReady(echartsInit.topProducts);
docReady(progressBar);
docReady(navbarTopDropShadow);
docReady(tooltipInit);
docReady(popoverInit);
docReady(flatpickrInit);
docReady(toastInit);
docReady(progressAnimationToggle);
docReady(glightboxInit);
docReady(plyrInit);
