import utils from './utils';
/* -------------------------------------------------------------------------- */
/*                                  Detector                                  */
/* -------------------------------------------------------------------------- */

const detector = () => {
  const $html = document.querySelector('html');

  if (window.is.opera()) utils.addClass($html, 'opera');
  if (window.is.mobile()) utils.addClass($html, 'mobile');
  if (window.is.firefox()) utils.addClass($html, 'firefox');
  if (window.is.safari()) utils.addClass($html, 'safari');
  if (window.is.ios()) utils.addClass($html, 'ios');
  if (window.is.iphone()) utils.addClass($html, 'iphone');
  if (window.is.ipad()) utils.addClass($html, 'ipad');
  if (window.is.ie()) utils.addClass($html, 'ie');
  if (window.is.edge()) utils.addClass($html, 'edge');
  if (window.is.chrome()) utils.addClass($html, 'chrome');
  if (window.is.mac()) utils.addClass($html, 'osx');
  if (window.is.windows()) utils.addClass($html, 'windows');
  if (navigator.userAgent.match('CriOS')) utils.addClass($html, 'chrome');
};

export default detector;
